import NetworkAccountMainMenu from 'modules/network-accounts/menus/NetworkAccountMainMenu';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

const SecondaryMenu = (props) => {
  return (
    <Switch>
      <Route path='/accounts' component={NetworkAccountMainMenu} />
    </Switch>
  );
};

export default SecondaryMenu;
