import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import React from 'react';

const NetworkAccountInfoDrawer = (props) => {
  return (
    <AutomaticDrawer title='Account Settings' {...props}>
      This is NetworkAccountInfoDrawer
    </AutomaticDrawer>
  );
};

NetworkAccountInfoDrawer.requiredParams = {
  account_settings: (p) => parseInt(p) > 0,
};

export default NetworkAccountInfoDrawer;
