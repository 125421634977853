import { useEffect } from 'react';
import { useDefaultWorkspaceQuery } from 'store/redux/apis/workspace/workspace.api';
import useAuth from 'store/redux/hooks/useAuth';
import { useLoader } from 'store/redux/states/ui/ui.hooks';

export default function useWorkspace() {
  const { auth } = useAuth();
  const {
    data = {},
    isLoading,
    isFetching,
    isError,
    currentData,
  } = useDefaultWorkspaceQuery(null, {
    skip: auth === false,
  });

  const [, setGlobalLoader] = useLoader('global');

  useEffect(() => {
    // @todo refactor to handle error
    setGlobalLoader((isLoading || isError || isFetching) && !currentData);
  }, [isLoading, isError, isFetching]);

  const hasActivePlan = () => {
    return data?.plan?.active === true;
  };

  const hasFreePlan = () => {
    return data?.plan?.free_trial === true;
  };

  const getFreeDaysLeft = () => {
    return data?.plan?.free_days_left;
  };
  const hasPaidPlan = () => {
    return data?.plan?.free_trial === false && data?.plan?.active === false;
  };

  return {
    workspace: data,
    isLoading: isLoading || !data?.id,
    isError,
    hasFreePlan,
    hasActivePlan,
    hasPaidPlan,
    getFreeDaysLeft,
  };
}
