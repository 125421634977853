import { CssBaseline, LinearProgress } from '@material-ui/core';
import '@ninja';
import App from './App';
import MuiThemeWrapper from 'common/@mui/theme';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store/redux/store';
import { NotificationsContextProvider } from 'store/NotificationsContext';
import { ConfigProvider } from 'antd';
import './styles/index.css';
import { UrlContextProvider } from 'hooks/useQueryParams';

const Bootstrap = () => {
  return (
    <BrowserRouter>
      <MuiThemeWrapper>
        <ReduxProvider store={store}>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <NotificationsContextProvider>
              <UrlContextProvider>
                <Suspense fallback={<LinearProgress />}>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: '#ff4e00',
                        colorSuccess: '#91c716',
                        colorWarning: '#ffbb02',
                        colorError: '#eb2f20',
                        colorInfo: '#2c7df0',
                        colorTextBase: '#151a22',
                        wireframe: false,
                        fontSize: 16,
                        fontFamily: 'fregular',
                      },
                      components: {
                        Input: {
                          controlHeightLG: 53,
                        },
                        Select: {
                          controlHeightLG: 53,
                        },
                        Form: {
                          verticalLabelPadding: '0px 0px 2px',
                          itemMarginBottom: '8px',
                          labelFontSize: '14px',
                        },
                      },
                    }}
                  >
                    <App />
                  </ConfigProvider>
                </Suspense>
              </UrlContextProvider>
            </NotificationsContextProvider>
          </SnackbarProvider>
        </ReduxProvider>
      </MuiThemeWrapper>
    </BrowserRouter>
  );
};

export default Bootstrap;
