import { Input } from 'antd';
import { Page } from 'components/layouts/components';
import { apps } from 'modules/AppsModule/apps.config';
import React, { useEffect, useState } from 'react';
import Ninja from '@ninja';
import AppCard from 'modules/AppsModule/components/AppCard';
import useSearchParams from 'common/hooks/useSearchParams';
import { useRequestShopifyAuthorizationMutation } from 'store/redux/apis/apps/shopify.api';
import { useSharedData } from 'store/redux/states/ui/ui.hooks';
import { AlertType } from 'components/Dialogs/const';

const AppsPage = (props) => {
  const [search, setSearch] = useState(Ninja.getUrlParam('search', ''));
  const url_app = Ninja.getUrlParam('app', null);
  const [chosenApp, setChosenApp] = useSharedData('chosen_app');
  const params = useSearchParams();
  const [requestAuthorizationQuery] = useRequestShopifyAuthorizationMutation();

  useEffect(() => {
    if (params.integration === 'shopify') {
      requestAuthorizationQuery({
        store_domain: params.shop,
        form_name: params.form_name,
        store_name: params.store_name,
      })
        .unwrap()
        .then((data) => {
          const newWindow = window.open(data.url, '_blank');

          // Check if the window was blocked
          if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            window.alert(
              'Pop-up was blocked. Please allow pop-ups for this website.',
              () => {
                window.open(data.url, '_blank');
              },
              // same func for on cancel too
              () => {
                window.open(data.url, '_blank');
              },
              { type: AlertType.Warning }
            );
          } else {
            window.open(data.url, '_blank');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [params]);

  const filtered_apps = React.useMemo(() => {
    if (search === '') return apps;
    const search_lowercase = search.toLowerCase();
    const filtered = {};

    Object.entries(apps).forEach(([key, app]) => {
      if (
        app.name.toLowerCase().includes(search_lowercase) ||
        app.description.toLowerCase().includes(search_lowercase) ||
        app.tags?.join(',').toLowerCase().includes(search_lowercase)
      ) {
        filtered[key] = app;
      }
    });

    return filtered;
  }, [search]);

  useEffect(() => {
    if (url_app !== chosenApp) {
      setChosenApp(url_app);
    }
  }, [url_app]);

  return (
    <Page>
      <div className='flex flex-col'>
        <div className='flex w-full'>
          <Input.Search
            placeholder='Search Apps'
            width={300}
            type='search'
            size='middle'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className='grid grid-cols-12 gap-4 w-full pt-4'>
          {Object.entries(filtered_apps).map(([key, app]) => {
            return (
              <div className='col-span-12 sm:col-span-4 md:col-span-3'>
                <AppCard key={key} app={app} />
              </div>
            );
          })}
        </div>
      </div>
    </Page>
  );
};

export default AppsPage;
