import MailerLiteAppDrawer from 'modules/AppsModule/apps/MailerLiteApp/MailerLiteAppDrawer';
import ShopifyApp from './apps/ShopifyApp';
import WoocommerceApp from './apps/WoocommerceApp';

export const apps = {
  woocommerce: {
    app: 'woocommerce',
    name: 'WooCommerce',
    description: 'Integrate ant track your sales with MyDataNinja',
    image: '/images/woocommerce-integration.svg',
    tags: ['Woocommerce', 'wordpress', 'ecommerce', 'sales', 'products', 'shop'],
    component: WoocommerceApp,
  },
  // facebook: {
  //   app: 'facebook',
  //   name: 'Meta',
  //   description: 'Connect Ads Manager',
  //   image: '/images/meta-integration.png',
  //   tags: ['Meta', 'facebook', 'ads', 'spend', 'ads manager'],
  // },
  // 'google-ads': {
  //   app: 'google-ads',
  //   name: 'Google Ads',
  //   description: 'Connect Google Ads',
  //   image: '/images/google-ads-integration.png',
  //   tags: ['google', 'google ads', 'ads', 'spend', 'google ads manager'],
  // },
  shopify: {
    app: 'shopify',
    name: 'Shopify',
    description: 'Connect Shopify',
    image: '/images/shopify-integration.png',
    tags: ['Woocommerce', 'shopify', 'ecommerce', 'sales', 'products', 'shop'],
    component: ShopifyApp,
  },
  mailerlite: {
    app: 'mailerlite',
    name: 'MailerLite',
    description: 'Connect MailerLite',
    image: '/images/mailerlite-integration.svg',
    tags: ['Mail', 'marketing', 'mailerlite', 'flow', 'email', 'shop'],
    component: MailerLiteAppDrawer,
  },
};
