import React, { useState } from 'react';
import ConnectedShopifyStores from './ConnectedShopifyStores';
import { Alert, Button } from 'antd';
import ShopifyConnectDrawer from './ShopifyConnectDrawer';
import Ninja from '@ninja';

const ShopifyAppDrawer = (props) => {
  const [connectModalOpened, setConenctModalOpened] = useState(
    Boolean(Ninja.getUrlParam('connect', false))
  );
  const [success, setSuccess] = useState(Boolean(Ninja.getUrlParam('success', false)));

  return (
    <div className='flex flex-col gap-4'>
      <div className='py-4'>
        <Button type='primary' onClick={() => setConenctModalOpened(true)}>
          Connect New Store
        </Button>
      </div>

      {success && (
        <div className='w-full'>
          <Alert type='success' message='Your store successfylly connected' />
        </div>
      )}

      <div>
        <ConnectedShopifyStores />
      </div>

      <ShopifyConnectDrawer
        open={connectModalOpened}
        onClose={() => setConenctModalOpened(false)}
      />
    </div>
  );
};

export default ShopifyAppDrawer;
