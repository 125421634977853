import CreateButton from 'components/buttons/CreateButton';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm, Space } from 'antd';
import ConversionFormatter from 'components/table-formatters/conversions/ConversionFormatter';
import { useGetNetworkAccountQuery } from 'store/redux/apis/network-accounts/network-accounts.api';
import { useCreateDefaultConversionsMutation } from 'modules/conversions/api/conversions.api';

const CreateAccountDefaultConversionsButton = (props) => {
  const { accountId, ...rest } = props;
  const { data: account } = useGetNetworkAccountQuery(accountId);

  const conversions = ['Dataninja Purchase', 'Dataninja Lead'];
  const [createDefaultConversionsQuery, { isLoading }] = useCreateDefaultConversionsMutation();

  const createDefaultConversions = () => {
    createDefaultConversionsQuery({
      account_id: accountId,
    }).unwrap();
  };

  return (
    <Popconfirm
      title='Create default conversions'
      description={
        <Fragment>
          After confirm this will create following conversions <br />
          <br />
          <Space direction='vertical' className='w-full'>
            {conversions.map((conversion) => {
              return (
                <ConversionFormatter
                  key={conversion}
                  row={{
                    name: conversion,
                    network: account?.network,
                    status: 'paused',
                  }}
                />
              );
            })}
          </Space>
        </Fragment>
      }
      onConfirm={createDefaultConversions}
      okText='Create'
      cancelText='Cancel'
    >
      <CreateButton loading={isLoading} {...rest} />
    </Popconfirm>
  );
};

CreateAccountDefaultConversionsButton.propTypes = {
  accountId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
};

export default CreateAccountDefaultConversionsButton;
