import React, { useState } from 'react';
import { Input, Button } from 'antd';
import Ninja from '@ninja';

const MailerLiteAppDrawer = (props) => {
  const [connectModalOpened, setConenctModalOpened] = useState(
    Boolean(Ninja.getUrlParam('connect', false))
  );

  return (
    <div className='flex flex-col gap-4'>
      <div className='py-4'>
        <Button type='primary' onClick={() => setConenctModalOpened(true)}>
          Connect MailerLite
        </Button>
      </div>

      <Input placeholder='Enter MailerLite Token' />

      <div className='w-full flex-center'>
        <Button type='primary'>Connect</Button>
      </div>
    </div>
  );
};

export default MailerLiteAppDrawer;
