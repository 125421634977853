import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import Icon from '@mdi/react';
import clsx from 'clsx';

const TableIcon = (props) => {
  const {
    icon,
    size = '1rem',
    color = 'black',
    tooltip,
    tooltipProps = {},
    onClick,
    onlyOnHover = false,
    ...rest
  } = props;

  const iconComponent = (
    <span
      className={clsx({
        'flex items-center action-icon ': true,
        'on-hover': onlyOnHover,
      })}
    >
      <Icon onClick={props.onClick} color={color} path={icon} size={size} {...rest} />
    </span>
  );

  if (tooltip) {
    return (
      <Tooltip placement='top' title={tooltip} destroyTooltipOnHide {...tooltipProps}>
        {iconComponent}
      </Tooltip>
    );
  }

  return iconComponent;
};

TableIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  onlyOnHover: PropTypes.boolean,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipProps: PropTypes.object,
};

export default TableIcon;
