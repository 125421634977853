import React from 'react';
import { Alert, Space } from 'antd';
import SyncFacebookPixelButton from 'modules/facebook-pixel/SyncFacebookPixelButton';
import PropTypes from 'prop-types';
import { useGetNetworkAccountQuery } from 'store/redux/apis/network-accounts/network-accounts.api';
import CreateFacebookPixelButton from 'modules/facebook-pixel/CreateFacebookPixelButton';

const FacebookPixelExistenceAlert = (props) => {
  const { accountId, ...rest } = props;

  const { data: account, isFetching } = useGetNetworkAccountQuery(accountId);

  if (isFetching) return null;

  if (account.has_pixels) return null;

  return (
    <Alert
      message='Account doesnt have a pixel. Please create a pixel to track conversions.'
      type='error'
      action={
        <Space direction='vertical'>
          <CreateFacebookPixelButton accountId={accountId} size='small' />
          <SyncFacebookPixelButton accountId={accountId} />
        </Space>
      }
      {...rest}
    />
  );
};

FacebookPixelExistenceAlert.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default FacebookPixelExistenceAlert;
