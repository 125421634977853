import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import React from 'react';

const NetworkAccountSettingsDrawer = (props) => {
  return (
    <AutomaticDrawer title='Account Settings' {...props}>
      This is NetworkAccountSettingsDrawer
    </AutomaticDrawer>
  );
};

NetworkAccountSettingsDrawer.requiredParams = {
  account_info: (p) => parseInt(p) > 0,
};

export default NetworkAccountSettingsDrawer;
