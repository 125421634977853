import useQueryParams from 'hooks/useQueryParams';
import CreateAccountPixelDrawer from 'modules/facebook-pixel/drawers/CreateFacebookPixelDrawer';
import AccountConversionsDrawer from 'modules/conversions/drawers/AccountConversionsDrawer';
import NetworkAccountInfoDrawer from 'modules/network-accounts/drawers/NetworkAccountInfoDrawer';
import NetworkAccountSettingsDrawer from 'modules/network-accounts/drawers/NetworkAccountSettingsDrawer';
import React from 'react';

const drawers = [
  NetworkAccountSettingsDrawer,
  NetworkAccountInfoDrawer,
  AccountConversionsDrawer,
  CreateAccountPixelDrawer,
];

const Drawers = () => {
  const params = useQueryParams();

  return drawers.map((Drawer, ind) => {
    const open = shouldRenderDrawer(Drawer, params.searchParams);
    if (!open) return null;
    const props = {
      searchParams: params.searchParams,
      destroyOnClose: true,
      afterOpenChange: (open) => {
        console.log(open);
        if (!open) {
          params.removeParams(Object.keys(Drawer.requiredParams));
        }
      },
    };

    return <Drawer key={ind} {...props} />;
  });
};

const shouldRenderDrawer = (drawer, searchParams) => {
  if (
    !drawer.requiredParams ||
    Object.keys(drawer.requiredParams).length === 0 ||
    Object.keys(searchParams).length === 0
  ) {
    return false;
  }

  return Object.entries(drawer.requiredParams).every(([param, check]) => {
    if (typeof check !== 'function') {
      return Boolean(searchParams[param]);
    } else {
      return check(searchParams[param]);
    }
  });

  return true;
};

export default Drawers;
