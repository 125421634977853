import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import NetworkConversionsTable from 'components/tables/conversions/NetworkConversionsTable';
import PropTypes from 'prop-types';
import { useGetNetworkAccountQuery } from 'store/redux/apis/network-accounts/network-accounts.api';
import FacebookPixelExistenceAlert from 'modules/facebook-pixel/alrets/FacebookPixelExistenceAlert';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import NetworkAccountsConversionsAlert from 'modules/conversions/alerts/NetworkAccountsConversionsAlert';

const AccountConversionsDrawer = (props) => {
  const accountId = parseInt(props.searchParams.account_id);
  const { data: account, isFetching } = useGetNetworkAccountQuery(accountId);

  useEffect(() => {
    if (!account?.id) return;
  }, [account]);

  return (
    <AutomaticDrawer
      size='large'
      title={isFetching ? 'Account Conversions Setup' : `${account.name} Conversions Setup`}
      {...props}
    >
      <Skeleton loading={isFetching}>
        <div className='flex flex-col gap-4'>
          {account?.network === 'facebook' ? (
            <FacebookPixelExistenceAlert accountId={accountId} />
          ) : (
            <NetworkAccountsConversionsAlert accountId={accountId} />
          )}
          <NetworkConversionsTable
            title='Account conversions'
            per_page={10}
            defaultFilters={{ account_id: accountId }}
          />
        </div>
      </Skeleton>
    </AutomaticDrawer>
  );
};

AccountConversionsDrawer.requiredParams = {
  conversions: (value) => value === 'true',
  account_id: true,
};

AccountConversionsDrawer.propTypes = {
  searchParams: PropTypes.object.isRequired,
};

export default AccountConversionsDrawer;
