import React, { Fragment, useEffect, useMemo } from 'react';
import { Alert, Button, Drawer } from 'antd';
import TextField from 'components/form/fields/TextField';
import { useFormik } from 'formik';
import Icon from '@mdi/react';
import { mdiLink } from '@mdi/js';
import * as yup from 'yup';
import { useRequestShopifyAuthorizationMutation } from 'store/redux/apis/apps/shopify.api';

const ShopifyConnectDrawer = (props) => {
  const { ...rest } = props;
  const [requestAuthorizationQuery, { isLoading: isConnectLoading }] =
    useRequestShopifyAuthorizationMutation();

  const onFormSubmit = () => {
    requestAuthorizationQuery(form.values)
      .unwrap()
      .then((data) => {
        window.open(data.url, '_blank');
      });
  };

  const form = useFormik({
    initialValues: {
      store_domain: 'quickstart-54710ecb.myshopify.com',
      form_name: 'Test Store Form 331',
      store_name: 'Test Store 331',
    },
    onSubmit: onFormSubmit,
    // validationSchema: validationSchema,
  });

  return (
    <Drawer destroyOnClose title='Connect Shopify Store' {...rest}>
      <div className='flex flex-col gap-6 mt-4'>
        <TextField
          error={form.touched.store_name && form.errors.store_name}
          helperText={form.errors.store_name}
          label='Store Name'
          name='store_name'
          value={form.values.store_name}
          onChange={form.handleChange}
        />
        <TextField
          error={form.touched.form_name && form.errors.form_name}
          helperText={form.errors.form_name}
          label='Form Name'
          name='form_name'
          value={form.values.form_name}
          onChange={form.handleChange}
        />
        <TextField
          label='Store Url'
          name='store_domain'
          value={form.values.store_domain}
          error={form.touched.store_domain && form.errors.store_domain}
          defaultValue={form.values.store_domain}
          helperText={form.errors.store_domain}
          onChange={form.handleChange}
        />

        <div className='flex justify-center'>
          <Button
            className='flex items-center justify-center'
            loading={isConnectLoading}
            type='primary'
            onClick={form.handleSubmit}
            icon={<Icon path={mdiLink} />}
          >
            Connect
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

const validationSchema = yup.object({
  form: yup.string().url('Please enter a valid URL').required('Please enter a valid URL'),
  name: yup.string('Enter name').required('Enter name').min(2).max(50),
});

export default ShopifyConnectDrawer;
