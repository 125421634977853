import AppLayout from 'components/layouts/AppLayout';
import React, { Fragment, lazy, useEffect } from 'react';
import P from 'router/paths';
import AlertMessage from '../components/Dialogs/AlertMessage';
import { WIZARD_STEP } from './WizardModule/wizardUtils';
import AccountConnectModal from 'components/Modals/AccountConnectModal';
import AdDetailDrawer from 'components/Drawers/AdDetailDrawer/AdDetailDrawer';
import Module from 'modules/Module';
import useAuth from 'store/redux/hooks/useAuth';
import ChoosePlanModal from 'components/Modals/ChoosePlanModal';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { useModal } from 'store/redux/states/ui/ui.hooks';
import AdsetCopyModal from 'components/Modals/AdsetCopyModal';
import CampaignCopyModal from 'components/Modals/CampaignCopyModal';
import FreeTrialNotice from 'components/FreeTrialNotice';
import SocketNotifications from 'components/notifications/SocketNotifications';
import AppsModule from './AppsModule';
import AppsDrawer from './AppsModule/apps/AppsDrawer';
import BillingModule from './BillingModule';
import useSocket from 'utils/socket/useSocket';
import Drawers from 'modules/Drawers';

const AdModule = lazy(() => import('modules/AdModule'));
const AudiencesModule = lazy(() => import('modules/AudiencesModule'));
const ConversionsModule = lazy(() => import('modules/ConversionsModule'));
const DashboardModule = lazy(() => import('modules/DashboardModule'));
const SetupModule = lazy(() => import('modules/SetupModule'));
const CRMModule = lazy(() => import('./CRMModule'));
const InternalPagesModule = lazy(() => import('./InternalPagesModule'));
const ReportingModule = lazy(() => import('./ReportingModule'));
const SettingsModule = lazy(() => import('./SettingsModule'));
const WebsiteBuilderModule = lazy(() => import('./WebsiteBuilderModule/WebsiteBuilderModule'));
const SalesModule = lazy(() => import('./SalesModule'));
const DomainModule = lazy(() => import('./DomainModule'));
const NotificationsModule = lazy(() => import('./NotificationsModule/NotificationsModule'));
const PostbacksModule = lazy(() => import('./PostbacksModule'));
const UrlModule = lazy(() => import('./UrlModule'));
const WizardModule = lazy(() => import('./WizardModule'));
const AccountsModule = lazy(() => import('./AccountsModule'));
const ConversionRulesModule = lazy(() => import('./ConversionRulesModule'));
const { Error404Page, PermissionErrorPage } = lazy(() => import('./ErrorsModule'));

const appModule = new Module({
  routes: [
    {
      path: P.APP,
      component: DashboardModule,
      exact: true,
      middleware: ['auth'],
    },
    {
      path: P.DASHBOARD.INDEX,
      component: DashboardModule,
      middleware: ['auth', `wizard:${WIZARD_STEP.completed}`],
    },
    { path: P.NOTIFICATIONS.INDEX, component: NotificationsModule, middleware: ['auth'] },
    { path: P.SETUP.INDEX, component: SetupModule, middleware: [`wizard:${WIZARD_STEP.setup}`] },
    {
      path: P.AD.INDEX,
      component: AdModule,
      middleware: [`wizard:${WIZARD_STEP.ads}`],
    },
    { path: P.PROFILE.INDEX, component: SettingsModule },
    {
      path: P.REPORTING.INDEX,
      component: ReportingModule,
      middleware: [`wizard:${WIZARD_STEP.reporting}`],
    },
    { path: P.AUDIENCES.INDEX, component: AudiencesModule, middleware: ['auth'] },
    { path: P.CONVERSIONS.INDEX, component: ConversionsModule, middleware: ['auth'] },
    { path: P.CONVERSION_RULES.INDEX, component: ConversionRulesModule, middleware: ['auth'] },
    { path: P.EXTERNAL.INDEX, component: InternalPagesModule },
    { path: P.CRM.INDEX, component: CRMModule, middleware: [`wizard:${WIZARD_STEP.crm}`] },
    { path: P.BUILDER.INDEX, component: WebsiteBuilderModule },
    { path: P.SALES.INDEX, component: SalesModule, middleware: ['auth', 'viewer:sale'] },
    {
      path: P.DOMAIN.INDEX,
      component: DomainModule,
      middleware: [`wizard:${WIZARD_STEP.domain}`],
    },
    { path: P.WIZARD.INDEX, component: WizardModule },
    { path: P.POSTBACKS.INDEX, component: PostbacksModule, middleware: ['auth'] },
    { path: P.URL.INDEX, component: UrlModule, middleware: ['auth'] },
    { path: P.BILLING.INDEX, component: BillingModule, middleware: ['auth'] },
    {
      path: P.ACCOUNTS.INDEX,
      component: AccountsModule,
      middleware: [`wizard:${WIZARD_STEP.accounts}`],
    },
    {
      path: P.APPS.INDEX,
      component: AppsModule,
    },
    {
      path: '/no-permission',
      component: PermissionErrorPage,
    },
    {
      path: '*',
      component: Error404Page,
    },
  ],
});

const BootstrapAppModule = () => {
  const { user } = useAuth();
  const { socket } = useSocket();
  const { workspace, isLoading: isWorkspaceLoading, hasActivePlan } = useWorkspace();
  const [choosePlanModalOpened, setChoosePlanModalOpened] = useModal('choose_plan');

  useEffect(() => {
    if (!user || !user?.name) return () => {};
    /** eslint-disable-next-line */
    if (typeof window.$crisp !== 'undefined') {
      window.$crisp.push(['set', 'user:nickname', [user.name]]);
      window.$crisp.push(['set', 'user:email', [user.email]]);
    }
  }, [user]);

  useEffect(() => {
    if (isWorkspaceLoading) return () => {};

    if (!hasActivePlan() && !choosePlanModalOpened) {
      setChoosePlanModalOpened(true);
    } else {
      setChoosePlanModalOpened(false);
    }
  }, [workspace, isWorkspaceLoading]);

  useEffect(() => {
    // initSocket(storage.get(tokenKey), {});
  }, []);

  return (
    <Fragment>
      <SocketNotifications />
      <Drawers />
      <AppLayout>
        <FreeTrialNotice />
        {appModule.render()}
      </AppLayout>
      <AlertMessage />
      <AccountConnectModal />
      <AdDetailDrawer />
      <ChoosePlanModal
        onCancel={() => setChoosePlanModalOpened(false)}
        open={choosePlanModalOpened}
      />
      <AdsetCopyModal />
      <CampaignCopyModal />
      <AppsDrawer />
    </Fragment>
  );
};

export default BootstrapAppModule;
