import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const UrlContext = React.createContext();

export const UrlContextProvider = ({ children }) => {
  const search = useLocation().search;
  const history = useHistory();
  const [urlSearchParams, setUrlSearchParams] = useState(new URLSearchParams(search));
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    const urlSearch = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearch.entries());
    setUrlSearchParams(urlSearch);
    setSearchParams(params);
  }, [search]);

  /**
   * Set url parameters
   * @param {Object<String,any>} newParams Parameter name
   * @param {Boolean} withHistory
   */
  const setUrlParams = (newParams, withHistory = false) => {
    for (const key in newParams) {
      urlSearchParams.set(key, newParams[key]);
    }
    const method = withHistory ? 'push' : 'replace';
    history[method](`${window.location.pathname}?${urlSearchParams}`);
  };

  /**
   * Set url parameter
   * @param {String} param Parameter name
   * @param {any} value Paramter value
   * @param {Boolean} withHistory
   */
  const setUrlParam = (param, value, withHistory = false) => {
    setUrlParams({ [param]: value }, withHistory);
  };

  /**
   * Remove url parameters
   * @param {Array<String>} params Parameters to remove from query
   * @param {Boolean} withHistory
   */
  const removeParams = (params, withHistory = false) => {
    for (const param of params) {
      urlSearchParams.delete(param);
    }
    const method = withHistory ? 'push' : 'replace';
    history[method](`${window.location.pathname}?${urlSearchParams}`);
  };

  /**
   * Remove url parameter
   * @param {String} param Parameter to remove from query
   * @param {Boolean} withHistory
   */
  const removeParam = (param, withHistory = false) => {
    removeParams([param], withHistory);
  };

  return (
    <UrlContext.Provider
      value={{ searchParams, setUrlParams, setUrlParam, removeParams, removeParam }}
    >
      {children}
    </UrlContext.Provider>
  );
};

const useQueryParams = () => useContext(UrlContext);

export default useQueryParams;
